.fl {
  float: left;
}
.fr {
  float: right;
}
.clrfix {
  zoom: 1;
}
.clrfix:after {
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
  content: ".";
  font-size: 0;
}
.hide {
  display: none;
}
.hidden {
  visibility: hidden;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
